.category-list{
    width: 100%;
    margin: auto;
    color: rgb(14, 14, 14);
    /* background-image: linear-gradient(to right,rgb(243, 243, 243),rgb(248, 248, 248),rgb(241, 241, 241)); */
    /* padding: 10px; */
    border-radius: 20px;
}
.categoryname{
    color: rgb(92, 91, 91);
    padding: 10px;
    font-size: 17px;
    font-weight: 600;
}
.categorylist{
    margin: 10px;
    text-align: center;
}
@media screen and (max-width: 991px) {
   .category-list-page{
    display: none;
   }
}