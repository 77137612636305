

/* 
html, body {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
  scroll-behavior: smooth; 
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f0f0f0; 
}

.center {
  width: 80%; 
}

.app_videos {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
  overflow: hidden;
  scroll-snap-type: y mandatory; 
  padding: 10px;
}

.videoCard {
  position: relative;
  width: 100%;
  height: 100%;
  scroll-snap-align: start; 
}

.video_player {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px; 
}

.product-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.9); 
  padding: 15px;
  border-radius: 10px; 
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease-in-out;
  width: 90%; 
  max-width: 300px; 
}

.product-imgbox {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.product-icon {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.product-icon a {
  display: inline-block;
  padding: 8px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
  transition: background 0.3s, color 0.3s;
}

.new-label1,
.on-sale1 {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #fff;
  background-color: #ff4081;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px;
  z-index: 1;
}

.on-sale1 {
  left: auto;
  right: 10px;
  background-color: #29b6f6;
}

.product-detail {
  padding-top: 15px;
}

.product-box h6,
.product-box p {
  margin: 0;
  color: #333;
}

h6.price-title {
  font-weight: bold;
  color: #2c3e50;
}

.product-detail p {
  font-size: 14px;
  color: #777;
}

@media (max-width: 1200px) {
  .center {
    width: 90%;
  }
}

@media (max-width: 991px) {
  .app_videos {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 768px) {
  .app_videos {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .product-box {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .center {
    width: 100%;
  }

  .app_videos {
    gap: 10px;
  }
} */





/* 
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
}

.center {
  width: 100%;
  max-width: 1200px;
}

.app_videos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
}

.videoCard {
  position: relative;
  width: 100%;
}

.video_player {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.product-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  width: calc(100% - 30px); 
  max-width: 300px; 
}

.product-imgbox {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.product-icon {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.product-icon a {
  display: inline-block;
  padding: 8px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
  transition: background 0.3s, color 0.3s;
}

.new-label, .on-sale {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px;
  color: #fff;
}

.new-label {
  background-color: #ff4081; 
}

.on-sale {
  background-color: #29b6f6; 
  right: 10px;
  left: auto;
}

.product-detail {
  padding-top: 10px;
  text-align: center;
}

.product-detail .product-title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
}

.product-detail .prices {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-detail .price, .product-detail .discounted-price {
  font-size: 18px;
  font-weight: bold;
  color: #2c3e50;
}

.discounted-price {
  text-decoration: line-through; 
  margin-right: 10px;
}

@media (max-width: 1200px) {
  .app_videos {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (max-width: 991px) {
  .app_videos {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

@media (max-width: 768px) {
  .app_videos {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 15px;
  }
}

@media (max-width: 576px) {
  .center {
    padding: 10px;
  }
  
  .app_videos {
    gap: 10px;
  }

  .product-box {
    width: calc(100% - 20px);
    padding: 10px;
  }

  .product-detail .price, .product-detail .discounted-price {
    font-size: 16px;
  }
} */


 /*.main{
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;

}
.center{
  width: 70%;
  height: 70%;
  border-radius: 20px;

}




 .product-imgbox {
  position: relative;
  overflow: hidden;
}



.product-imgbox:hover img {
  transform: scale(1.1);
}

.product-icon a {
margin: 0 5px;
display: inline-block;
padding: 8px;
background: #ffffff;
border-radius: 50%;
box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
transition: background 0.3s, color 0.3s;
}



.new-label1, .on-sale1 {
position: absolute;
top: 10px;
left: 10px;
color: #fff;
background-color: #ff4081;
padding: 5px 10px;
font-size: 12px;
border-radius: 3px;
z-index: 1;
}

.on-sale1 {
left: auto;
right: 10px;
background-color: #29b6f6;
}


.product-box {
    background: #fff;
    border: 1px solid #eee;
    padding: 15px;
    margin-bottom: 20px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    border-radius: 5px;
    height: 100%; 
}

.product-detail {
padding-top: 15px;
}


.product-box h6, .product-box p {
margin: 0;
color: #333;
}

h6.price-title {
font-weight: bold;
color: #2c3e50;
}

.product-detail p {
font-size: 14px;
color: #777;
}

@media (max-width: 1400px){
.center{
  width: 40%;
}

}
@media (max-width: 1200px){
.center{
  width: 40%;
}

}

@media (max-width: 991px){
.center{
  width: 100%;
}

}

@media (max-width: 768px) {
.product-box {
    flex-direction: column;
}

.product-imgbox, .product-detail {
    width: 100%;
}
.center{
  width:50%;
}

}

@media (max-width: 576px){
.center{
width: 65%;
}
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  margin-bottom: 30px;
} 


.slick-dots {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  z-index: 10;
}

.slick-dots li {
  display: inline-block;
  margin: 0 5px;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 8px;
  height: 8px;
  padding: px;
  color: transparent;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

 */



 /* .main {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
  }
  
  .center {
    width: 30%;
    border-radius: 20px;
  } */
  


  
  .product-imgbox {
    position: relative;
    overflow: hidden;
  }
  
  .product-imgbox:hover img {
    transform: scale(1.1);
    /* Additional hover effect if required */
  }
  
  .product-icon a {
    margin: 0 5px;
    display: inline-block;
    padding: 8px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
    transition: background 0.3s, color 0.3s;
  }
  
  .new-label1,
  .on-sale1 {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #fff;
    background-color: #ff4081;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 3px;
    z-index: 1;
  }
  
  .on-sale1 {
    left: auto;
    right: 10px;
    background-color: #29b6f6;
  }
  
  .product-box {
    background: #fff;
    border: 1px solid #eee;
    padding: 5px;
    margin-bottom: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    height: 100%;
  }
  .reelsview{
    width: 300px;
  }
  
  .product-detail {
    padding-top: 15px;
  }
  
  .product-box h6,
  .product-box p {
    margin: 0;
    color: #333;
  }
  
  h6.price-title {
    font-weight: bold;
    color: #2c3e50;
  }
  
  .product-detail p {
    font-size: 14px;
    color: #777;
  }
  
  @media (max-width: 1400px) {
    .center {
      width: 40%;
    }
  }
  
  @media (max-width: 1200px) {
    .center {
      width: 40%;
    }
    .main  .product-box{
      width: 300px;
    }
  }
  
  @media (max-width: 991px) {
    .center {
      width: 100%;  
    
    }
   .main  .product-box{
      width: 300px;
    }
    
    
  
  }
  
  @media (max-width: 768px) {
    .product-box {
      flex-direction: column;
    }
  
    .product-imgbox,
    .product-detail {
      width: 100%;
    }
  
    .center {
      width: 50%;
    }
  }
  
  @media (max-width: 576px) {
    .center {
      width: 100%;
    }
    .main  .product-box{
      margin: auto;
      
    }
    
  }
  

  /* Desktop Styles */
.detail-title {
  display: flex;
  justify-content: space-between;
}



/* Mobile Styles */
@media (max-width: 1200px) {
  .detail-title {
      display: flex;
      flex-direction: column;
  }
  
  .detail-left,
  .detail-right {
      width: 100%;
  }

  .detail-left {
      margin-bottom: 10px; 
  
  }
}

  /* Additional styles for slick slider */
  /* .product-box:hover {
    .slick-slider {
      position: relative;
      display: block;
      box-sizing: border-box;
      user-select: none;
      touch-action: pan-y;
      margin-bottom: 10px;
      background-color: gray;
    }
  }
  
  .slick-dots {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    z-index: 10;
  }
  
  .slick-dots li {
    display: inline-block;
    margin: 0 5px;
  } */
  
  
  
  
  
  .product-box .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    margin-bottom: 10px;
    
  }
  
  .product-box:hover .slick-dots {
    bottom: -1px; 
    background-color: white;
    
  }
  
  .slick-dots {
    position: absolute;
    bottom: -30px; 
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    z-index: 10;
    transition: bottom 0.3s ease; 
  }
  
  .slick-dots li {
    display: inline-block;
    margin: 0 5px;
  }
  
  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 8px;
    height: 8px;
    padding: 5px;
    color: transparent;
    border: none;
    outline: none;
    background-color: rgba(0, 0, 0, 0.5); 
    border-radius: 50%; 
    cursor: pointer;
    transition: background-color 0.3s ease; 
  }
  
  .slick-dots li:hover button {
    background-color: rgba(255, 255, 255, 0.8); 
  }




     
/* .videoCard{
  position: relative;
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
} */

/* 
.video_player{
  object-fit: fill;
  width: 100%;
  height: 100%;
}
.videoFooter{
  display: none;
}
.product-box:hover{

  .videoFooter{
    display: block;
    position: absolute;
    bottom: 0;
  }
}
  */