/* BennerSlider.css */

/* Ensure carousel images fit the container */

.carousel-item img {
    width: 100%; /* Makes the image fit the width of the carousel container */
    height: 650px;
    object-fit: cover; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  /* Optionally, you can set a specific height for the carousel items */
  .carousel-item {
    height: 650px; /* Set desired height for the carousel items */
    overflow: hidden; /* Hide any overflow from the images */
  }
  
  /* Media query for mobile devices (width <= 768px) */
  @media (max-width: 768px) {
    .carousel-item {
      height: 300px; /* Adjust height for smaller screens */
    }
  }
  
.carousel-caption h3{
  color: #ff00ff !important;
}
.carousel-caption h1{
  color: #ffe5d9 !important;
}
.carousel-caption h2{
  color: #f9f7f3 !important;
}

  /* style={{color:"#930cd0 !important"}} */