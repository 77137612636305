@media (max-width: 454px) { 
    .cartStyle{
        display: flex;
        
    }   
    .shareBox1{
        /* background-color: aqua !important; */
        left: 50px;
        
    }
    
}

.DeleteButton{
    font-weight: 500;
    color: rgb(46, 4, 197);
}
.totalPrice{
    font-size: 25px!important;
    float: right;
    color: rgb(3, 3, 128);
}

.productInfo img{
    width: 150px;
    height: 150px;
}
@media (max-width: 776px) {
    .productInfo{
        font-size: 10px !important   ;
    }
    .productInfo img{
        width: 100px;
        height: 100px;
    }
    .productInfo span{
        font-size: 10px;
    }
    .productInfo h4{
        font-size: 10px;
    }
    .cartStyle{
        display: flex;
        width: 200px;
        padding: 10px !important;
        justify-content:end;
      
    }
    .shareicons{
        font-size: 20px !important;
    }
}

@media (max-width: 576px) {
.totalPrice{
    margin-top: -100px;
}
.totalPrice{
    font-size: 15px!important;
  
}}


.share-options{
    padding: 4px;
    margin-top: -30px;
}
.share-options li{
    padding: 0px;
}
.popover-body{
    margin-bottom: -20px;
}