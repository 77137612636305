* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

.primary-navigation {
  margin: 0 auto;
  padding: 5px 0;
  text-align: center;
  font-size: 16px;
  background-image: linear-gradient(to right,rgb(83, 83, 83),rgb(44, 44, 44),rgb(77, 77, 77));
}

.primary-navigation ul {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}

.primary-navigation ul li {
  display: inline-block;
  position: relative;
  margin-left:10px ;
  padding: 0 15px;
  border-left: 2px solid #3ca0e7;
  font-family: 'Arvo', serif;
}

.primary-navigation ul li a {
  color: rgb(255, 255, 255);
  text-decoration: none;
  display: block;
  padding: 5px 0;
  overflow: auto;
}

.primary-navigation ul li a:hover {
  color: #3ca0e7;
}

.primary-navigation ul li:hover {
  cursor: pointer;
}

/* Dropdown menu styling */
.primary-navigation ul li ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 100%; /* Position dropdown below parent */
  left: 0;
  background: white;
  box-shadow: 0px 3px 5px -1px #ccc;
  min-width: 200px;
  padding: 10px 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  border-radius: 4px;
  z-index: 1000; /* Ensure dropdown is above other content */
}

.primary-navigation ul li:hover > ul,
.primary-navigation ul li ul:hover {
  visibility: visible;
  opacity: 1;
}

/* Dropdown items styling */
.primary-navigation ul li ul li {
  width: 100%;
  margin-bottom: 0;
}

.primary-navigation ul li ul li a {
  display: block;
  padding: 10px 15px;
  color: #333;
  text-align: left;
  text-decoration: none;
  transition: padding-left 0.3s ease, border-left 0.3s ease;
}

/* .primary-navigation ul li ul li a:hover {
  padding-left: 25px;
  border-left: 2px solid #3ca0e7;
} */

@media (max-width: 1467px) {
  .primary-navigation ul li:nth-of-type(n+8) { 
    display: none; 
  }
}


@media (max-width: 1200px) {
  .primary-navigation ul li:nth-of-type(n+6) { 
    display: none; 
  }
}
@media (max-width: 999px) {
  .primary-navigation ul li:nth-of-type(n+4) {
    display: none; 
  }
}

@media (max-width: 992px) {
  .primary-navigation ul li:nth-of-type(n+5) {
    display: none; 
  }
}

@media (max-width: 768px) {
  .primary-navigation ul li:nth-of-type(n+4) { 
    display: none;
  }
}

@media (max-width: 591px) {
  .primary-navigation ul li:nth-of-type(n+3) { 
    display: none; 
  }
}

@media (max-width: 477px) {
  .primary-navigation  { 
    display: none; 
  }
}




/* Dropdown icon styling */
.category-item {
  position: relative; 
  display: flex; 
  align-items: center; 
}

.dropdown-icon {
  margin-left: 8px; 
  transition: transform 0.3s ease; 
  vertical-align: middle; 
  position: absolute; 
  right: 1;
  top: 6px; 
}

.primary-navigation ul li:hover .dropdown-icon {
  transform: rotate(180deg); 
}


.primary-navigation ul li:hover > ul {
  display: block; 
}


/* Additional styling for dropdown */


