/* .collection-img {
    position: relative;
}

.countdown-timer {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 245, 61, 0.6); 
    color: white; 
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
} */

/* @font-face {
    font-family: 'Digital-7';
    src: url('./digital-7\ \(mono\ italic\).ttf') format('woff');
} */


.countdown-timer {
    position: absolute;
    top: 0px;
    right: 0px;
    background-image: linear-gradient(to right, #b8cbb8 0%, #b8cbb8 0%, #b465da 0%, #cf6cc9 33%, #ee609c 66%, #ee609c 100%);
    padding: 5px;
    border-radius: 5px 0 0 5px;
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
  
        /* font-family: 'Digital-7', sans-serif; */

        font-style: italic;
}

/* Adding a pseudo-element for the shimmer effect */
.countdown-timer::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 50%;
    background: linear-gradient(45deg,
            rgba(255, 255, 255, 0) 20%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 0) 80%);
    opacity: 0.8;
    filter: brightness(3);
    /* Increase brightness for a more intense effect */
    transform: rotate(45deg);
    /* Tilt the shimmer effect */
    animation: shimmer 2s linear infinite;
    /* Animation duration and repetition */
}



/* Keyframes for shimmer animation */
@keyframes shimmer {
    0% {
        transform: translateX(-100%) rotate(45deg);
        /* Start position */
    }

    100% {
        transform: translateX(100%) rotate(45deg);
        /* End position */
    }
}

@media (max-width: 765px) {
    .countdown-timer{
        font-size: 12px;

    }
  }
@media (max-width: 477px) {
    .countdown-timer{
        font-size: 14px;

    }
  }
  

.linkToAmazon {
    position: absolute;

    
    background-image: linear-gradient(to right, #b8cbb8 0%, #b8cbb8 0%, #b465da 0%, #cf6cc9 33%, #ee609c 66%, #ee609c 100%);
    padding: 5px;
    border-radius: 5px 5px;
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-style: italic;
    cursor: pointer;

}

.linkToAmazon::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 50%;
    background: linear-gradient(45deg,
            rgba(255, 255, 255, 0) 20%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 0) 80%);
    opacity: 0.8;
    filter: brightness(3);
    /* Increase brightness for a more intense effect */
    transform: rotate(45deg);
    /* Tilt the shimmer effect */
    animation: shimmer1 2s linear infinite;
    /* Animation duration and repetition */
}

@keyframes shimmer1 {
    0% {
        transform: translateX(-100%) rotate(45deg);
        /* Start position */
    }

    100% {
        transform: translateX(100%) rotate(45deg);
        /* End position */
    }
}