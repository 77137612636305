

.message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
    background: linear-gradient(to right, #e1f5fe, #f1f8e9); /* Soft gradient background */
    padding: 20px;
}

.message-card {
    background-color: #ffffff;
    border-radius: 16px; /* Soft corners */
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    padding: 40px;
    max-width: 500px; /* Max width for better focus */
    width: 100%;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    animation: fadeIn 0.5s; /* Animation for card appearance */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.message-card h1 {
    font-size: 28px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #333; /* Darker text */
}

.message-card.success {
    border-left: 6px solid #4caf50; /* Green border */
    background: #e8f5e9; /* Light green background */
}

.message-card.error {
    border-left: 6px solid #f44336; /* Red border */
    background: #ffebee; /* Light red background */
}

.message-card p {
    font-size: 16px;
    margin: 15px 0;
    color: #555; /* Softer text color */
}

.redirect-text {
    font-size: 14px;
}

.redirect-button {
    margin-left: 5px;
    color: #007bff;
    background: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.3s, transform 0.3s;
}

.redirect-button:hover {
    color: #0056b3;
    transform: scale(1.05); /* Scale effect on hover */
}

.message-card:hover {
    transform: translateY(-5px); /* Slight lift on hover */
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15); /* Deeper shadow */
}

/* Icons for success and error */
.icon2{
    font-size: 50px; /* Size of the icon */
    margin-bottom: 20px; /* Spacing below the icon */
}

.success-icon {
    color: #4caf50; /* Green color for success */
}

.error-icon {
    color: #f44336; /* Red color for error */
}

/* Optional: Add media queries for responsiveness */
@media (max-width: 600px) {
    .message-card {
        padding: 20px; /* Less padding on smaller screens */
    }

    .message-card h1 {
        font-size: 24px; /* Smaller font for titles */
    }

    .message-card p {
        font-size: 14px; /* Smaller font for text */
    }
}
