/* styles.css */
.show1 {
    display: block; /* Default to block for mobile */
}

.show2 {
    display: none; /* Default to none for mobile */
}

@media (min-width: 768px) {
    .show1 {
        display: none; /* Hide show1 on larger screens */
    }

    .show2 {
        display: block; /* Show show2 on larger screens */
    }
}
/* @media (min-width: 991px) {
   .show2{
    place-items: center;
    place-content: center;
    text-align: center;
    justify-content: center;
   }
} */
