html{
  scroll-snap-type: mandatory;
}
.app{
  display: grid;
  place-items: center;
  height: 100vh;
  /* background-color: black; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
}




.app_videos{
  position: relative;
  height:72vh;
  background-color: white;
  width: 70%;
  border-radius: 20px;
  max-width: 450px;
  max-height: 1200px;
  overflow: scroll;
  scroll-snap-type: y mandatory;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
}

.app_videos::-webkit-scrollbar{
  display: none;
}
.app_videos{
  -ms-overflow-style: none;
  scrollbar-width: none;
}


/* .videoCard{
  position: relative;
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
}


.video_player{
  object-fit: fill;
  width: 100%;
  height: 100%;
} */
/* 
@media screen and (max-width: 1700px) {
  .app_videos{
    height: 70vh;
  }
} */
/* 
@media screen and (max-width: 1400px) {
  .app_videos{
    height: 60vh;
  }
} */

/* @media screen and (max-width: 1200px) {
  .app_videos{
    height: 55vh;
  }
    
} */
@media screen and (max-width: 1500px) {
  .app_videos{
    height: 66vh;
  }
    
}

@media screen and (max-width: 1400px) {
  .app_videos{
    height: 60vh;
  }
    
}

@media screen and (max-width: 1200px) {
  .app_videos{
    height: 60vh;
  }
    
}
@media screen and (max-width: 1024px) {
  .app_videos{
  height: 47vh;
  }
}


@media screen and (max-width: 991px) {
    .breakpoint{
        display: none;
    }
    .app_videos{
      height: 50vh;
    }
    
}
@media screen and (max-width: 778px) {
  .app_videos{
  height: 63vh;
  }
}

 @media screen and (max-width: 640px) {
  .app_videos{
    height: 50vh;
  }
    
}

@media screen and (max-width: 570px) {
  .app_videos{
  height: 45vh;
  }
    
  }
 
@media screen and (max-width: 480px) {
  .app_videos{
  height: 43vh;
  }
  .app{
    height: 60vh;
  }
    
    
} 
@media screen and (max-width: 410px) {
  .app_videos{
  height: 38vh;
  }
    
    
} 

@media screen and (max-width: 392px) {
  .app_videos{
  height: 47vh;
  }
} 

@media screen and (max-width: 360px) {
  .app_videos{
  height: 43vh;
  }
} 


