/* Ensure the list has bullet points */
.border-product1 ul {
    list-style-type: disc; 
    margin-left: 20px; 
    padding-left: 20px;
    font-size: 16px;
    color: black;
}

/* Ensure proper spacing for list items */
.border-product1 li.product-description1 {
    margin-bottom: 10px; /* Space between list items */
    line-height: 1.5; /* Adjust line height for readability */
}


@media (max-width: 991px) {
    .border-product1{
        text-align: left;
    }
    .border-product1 ul {
        font-size:small;
    }

}



.shareBox1 {
    /* background-image: linear-gradient(to right, #243949 0%, #496b86 100%); */
    background-color: #fff;
     /* width: 30px;
     height: 30px; */
     color: rgb(0, 0, 0);
    border-radius: 50%; /* Adjust the radius as needed */
    padding: 10px; /* Add some padding for better spacing */
    display: flex;
    align-items: center; /* Center the icon and text vertically */
    cursor: pointer; /* Change the cursor to indicate it's clickable */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: add shadow for depth */
    transition: background-color 0.3s; /* Optional: add a transition effect */
}
.boxshare1{
    color: black;
}

.shareBox1:hover {
    background-color: rgba(255, 255, 255, 1); /* Change background color on hover */
}


.shareBox1 {
    position: relative; /* This allows .boxshare to be positioned absolutely within it */
    display: inline-block; /* Inline-block to keep the box sizing and padding */
    cursor: pointer; /* Cursor indicates clickable area */
}

/* Initially hide the .boxshare */
.boxshare1 {
    display: none;
    position: absolute; /* Positioned absolutely to align below .shareBox */
    /* top: 0%;  */
    left: -30%;
    bottom: 90%;
    /* right: 90%; */

    background-color: #fff; /* Background color for the dropdown */
    border: 1px solid #ccc; /* Border for the dropdown */
    padding: 5px; /* Padding for spacing */
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Optional: Adds shadow effect */
    z-index: 1000; /* Ensure it appears above other content */
}

/* Show .boxshare on hover */
.shareBox1:hover .boxshare1 {
    display: block;
}
.shareButton{
    position: absolute;
    top: 5px;
     right: 18px;
     z-index: 1001 
}
@media (max-width: 480px) {
    .shareButton{
        margin-right: 30px;
    }
}
