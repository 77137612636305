

.product-detail .detail-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.material-icons {
    cursor: pointer;
    font-size: 20px;
}

.popover {
    position: absolute;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    /* padding: 5px; */
    z-index: 1000;
    display: flex;
    flex-direction: column;
    width: 150px;
    margin-top: -200px;
}

.popover header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popover .close-button {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

.popover .popover-title {
    margin: 0;
}

.popover-body {
    padding: 10px 0;
}

.share-options {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.share-options li {
    margin: 5px 0;
}

.share-option {
    text-decoration: none;
    display: flex;
    align-items: center;
}

.share-option .icon {
    width: 20px;
    height: 20px;
    background-size: cover;
    margin-right: 10px;
}

.share-option .label {
    font-size: 14px;
}

.arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.shareBox {
    position: relative; /* This allows .boxshare to be positioned absolutely within it */
    display: inline-block; /* Inline-block to keep the box sizing and padding */
    cursor: pointer; /* Cursor indicates clickable area */
}

/* Initially hide the .boxshare */
.boxshare {
    display: none;
    position: absolute; /* Positioned absolutely to align below .shareBox */
    /* top: 0%;  */
    left: -30%;
    bottom: 90%;
    /* right: 90%; */

    background-color: #fff; /* Background color for the dropdown */
    border: 1px solid #ccc; /* Border for the dropdown */
    padding: 5px; /* Padding for spacing */
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Optional: Adds shadow effect */
    z-index: 1000; /* Ensure it appears above other content */
}

/* Show .boxshare on hover */
.shareBox:hover .boxshare {
    display: block;
}
@media (max-width: 1200px) { 
     
    .detail-title1{
        flex-direction:row !important;
        justify-content: space-between !important;
        
    }
    
}